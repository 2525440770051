<template>
  <div>
    <b-tooltip
      target="warningSistemaIndisponivel"
      placement="bottom"
    >
      Estamos aprimorando nosso sistema para oferecer a você uma experiência ainda melhor. Em breve, algumas rotinas terão atualização em tempo real. Fique atento!
    </b-tooltip>
    <font-awesome-icon
      id="warningSistemaIndisponivel"
      class="warning-indisponivel"
      icon="fa-solid fa-tower-broadcast"
      size="lg"
      fade
      @click="useModalSistemaIndisponivel"
    />
  </div>
</template>

<style lang="scss">

.warning-indisponivel {
  background-color: #ffa5516c;
  color: #FF9F43;
  margin: .5rem;
  padding: 8px;
  border-radius: 50%;
}

</style>

<script>

/* eslint-disable no-param-reassign */
import Ripple from 'vue-ripple-directive'
import useModalSistemaIndisponivel from './shared/components/useModalSistemaIndisponivel'

export default {
  directives: {
    Ripple,
  },
  setup() {
    return {
      useModalSistemaIndisponivel,
    }
  },
}
</script>
