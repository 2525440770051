import Vue from 'vue'

export default function () {
  Vue.swal({
    title: '<div class="text-warning">Atenção</div>',
    html:
        `<div class="text-primary">
            Prezado Cliente,
            </br>Estamos empolgados em anunciar que estamos aprimorando nossa infraestrutura para oferecer um serviço ainda mais robusto e eficiente.
            </br>A partir de setembro de 2024, as atualizações poderão ser realizadas em tempo real.
            </br>Em breve, você poderá acompanhar seus créditos diretamente na 
            nossa ferramenta.
            </br>Com essas mudanças, estaremos introduzindo novas modalidades de planos para melhor atendê-lo.
            <br><br>Se você contratou nossos serviços antes de maio de 2024, pedimos que entre em contato com nossa equipe comercial ou de suporte para ajustar seu plano.

            <br><br>
                  
            Agradecemos sua paciência e compreensão enquanto trabalhamos para melhorar nossos serviços. 

            <br><br>

            Para mais informações, estamos a disposição através do <span class="text-nowrap">(11) 99158-8992</span>.
        </div><br>

        <div class="text-warning">
            Atenciosamente, Equipe Monitor Contábil
        </div>`,
    icon: 'info',
    iconColor: '#FF9F43',
    showConfirmButton: false,
    width: '42%',
    customClass: {
      popup: 'swal-m',
    },
  })
}
